<template>
  <div class="Admin">
    <AdminSideBar class="Admin-SideBar" />
    <div class="Admin-table">
      <!-- CHART -->

      <VueGoodTable
        v-if="!loadingTable"
        class="GoodTable scrollbar"
        :columns="columns"
        :rows="rows"
        max-height="450px"
        :fixed-header="true"
        @on-row-click="onRowClick"
        :search-options="{
          enabled: true
        }"
        theme="black-rhino"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 7
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'isActive'">
            <span :class="[props.row.isActive ? 'color-green' : 'color-blue']">
              {{ props.row.isActive }}
            </span>
          </span>
          <span v-else-if="props.column.field != 'isActive'">
            <span :class="[props.row[props.column.field] == 'not provided' && 'missing-value']">
              {{ props.row[props.column.field] }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </VueGoodTable>

      <VBtn
        class="update-btn"
        @click="updateUsers"
        v-if="$store.state.user.email === 'ppregoni@apogeaconsulting.com'"
      >
        Update
      </VBtn>
    </div>

    <!-- LOADING OVERLAY -->
    <LoadingOverlay :loading="loading" />
    <LoadingOverlay :loading="loadingTable" />
  </div>
</template>

<script>
// https://xaksis.github.io/vue-good-table/guide/configuration/#columns
import { VueGoodTable } from 'vue-good-table'
import LoadingOverlay from '@/components/common/LoadingOverlay.vue'
import AdminSideBar from '@/components/adminpanel/AdminSideBar.vue'
import { format } from 'date-fns'
// add to component
export default {
  name: 'Admin',
  components: {
    VueGoodTable,
    LoadingOverlay,
    AdminSideBar
  },
  data() {
    return {
      loading: false,
      columns: [
        { label: 'Email', field: 'email' },
        { label: 'Name', field: 'displayName' },
        { label: 'Phone', field: 'phoneNumber' },
        {
          label: 'Joined On',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'dd-MM-yyyy HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm:ss',
          sortable: true
        },
        { label: 'Role', field: 'role' },
        { label: 'Active', field: 'isActive', type: 'boolean' }
      ],
      loadingTable: true,
      rows: []
    }
  },
  async beforeMount() {
    this.fetchUsers()
  },

  methods: {
    async fetchUsers() {
      try {
        const { data } = await this.$functions.httpsCallable('usersActiveState')()
        console.log('🚀 ~ file: Home.vue ~ line 70 ~ fetchUsers ~ raton', data)
        this.rows = this.formatUsersData(data)
        this.loadingTable = false
      } catch (error) {
        console.log(error.message)
      }
    },
    async updateUsers() {
      const resp = await this.$functions.httpsCallable('updateUsersCreatedAt')()
      console.log('updateUsers -> resp', resp)
    },
    async onRowClick({ row }) {
      try {
        const isActive = this.rows[row.originalIndex].isActive
        this.loading = true
        const { data } = await this.$functions.httpsCallable('toggleUserActiveState')({
          uid: row.uid,
          isActive: !isActive
        })
        console.log('🚀 ~ file: Home.vue ~ line 81 ~ onRowClick ~ data', data)

        this.rows[row.originalIndex].isActive = !isActive
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error.message)
      }
    },
    formatDate(date) {
      const formatedDate = format(new Date(date), 'dd-MM-yyyy HH:mm:ss')
      return formatedDate
    },
    formatUsersData(data) {
      return data.map(user => {
        for (const key in user) {
          if (!user[key] && key !== 'isActive') user[key] = 'not provided'
          if (key === 'createdAt') user[key] = this.formatDate(user[key])
        }
        return user
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  font-weight: bold;
  color: #02b201;
}
.color-blue {
  font-weight: bold;
  color: #409eff;
}
.Admin {
  height: calc(100vh - 84px);
  position: relative;
  background: #333333;
  display: flex;
  overflow: hidden;
  &-table {
    display: flex;
    justify-content: center;
    min-width: 700px;
    width: 100%;
    padding: 40px;
    .GoodTable {
      width: 100%;
      min-height: 600px;
    }
  }
  &-SideBar {
    background: #1e1e1e;
    border-right: 1px solid #cfcdcd52;
    min-height: calc(100vh - 84px);
    width: 220px;
    background-attachment: fixed;
    background-color: #32383e;
    background-image: url(//www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png);
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: 256px 556px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    width: 256px;
  }
  .update-btn {
    @include posAbsolute(98%, 98%);
  }
}
.message-box {
  text-align: center;
  margin-top: 0px;
  background-color: #f5fbff;
  width: 500px;
  color: #006bb8;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #b8e1ff;
}
.sortable {
  user-select: none;
}
.missing-value {
  color: rgb(117, 117, 117);
  font-style: italic;
  font-size: 12px;
}
.vgt-left-align {
  span {
    user-select: none;
  }
}
</style>
