<template>
  <div class="Admin-side">
    <div class="pic-container">
      <img class="profile-pic" src="@/assets/avatar.png" />
    </div>
    <div class="Admin-side-footer">
      <h5 class="Admin-side-footer-title">{{ $store.getters.userNick }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminSideBar'
}
</script>

<style lang="scss" scoped>
.Admin-side {
  overflow: hidden;

  display: flex;
  flex-direction: column;
  background: #32383e;
  user-select: none;
  .pic-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .profile-pic {
      width: 75%;
      height: 75%;
      object-fit: contain;
    }
  }

  &-footer {
    background-image: linear-gradient(#484e55b6, #3a3f44be 60%, #313539c7);
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &-title {
      color: white;
      margin-bottom: 0;
    }
  }
}
</style>
