var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Admin-side" }, [
    _vm._m(0),
    _c("div", { staticClass: "Admin-side-footer" }, [
      _c("h5", { staticClass: "Admin-side-footer-title" }, [
        _vm._v(_vm._s(_vm.$store.getters.userNick))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic-container" }, [
      _c("img", {
        staticClass: "profile-pic",
        attrs: { src: require("@/assets/avatar.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }