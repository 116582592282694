var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Admin" },
    [
      _c("AdminSideBar", { staticClass: "Admin-SideBar" }),
      _c(
        "div",
        { staticClass: "Admin-table" },
        [
          !_vm.loadingTable
            ? _c("VueGoodTable", {
                staticClass: "GoodTable scrollbar",
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.rows,
                  "max-height": "450px",
                  "fixed-header": true,
                  "search-options": {
                    enabled: true
                  },
                  theme: "black-rhino",
                  "pagination-options": {
                    enabled: true,
                    mode: "records",
                    perPage: 7
                  }
                },
                on: { "on-row-click": _vm.onRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "isActive"
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    class: [
                                      props.row.isActive
                                        ? "color-green"
                                        : "color-blue"
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(props.row.isActive) + " "
                                    )
                                  ]
                                )
                              ])
                            : props.column.field != "isActive"
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    class: [
                                      props.row[props.column.field] ==
                                        "not provided" && "missing-value"
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(props.row[props.column.field]) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1408592204
                )
              })
            : _vm._e(),
          _vm.$store.state.user.email === "ppregoni@apogeaconsulting.com"
            ? _c(
                "VBtn",
                { staticClass: "update-btn", on: { click: _vm.updateUsers } },
                [_vm._v(" Update ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("LoadingOverlay", { attrs: { loading: _vm.loading } }),
      _c("LoadingOverlay", { attrs: { loading: _vm.loadingTable } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }